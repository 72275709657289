<template>
  <div class='project-detail pos-fixed right-0 top-0 flex flex-column md:max-w-30-rem h-100-per p-0-5 w-100-per z-12' v-if="currentProject">
    <nav class='pos-relative bg-white w-100-per p-1 p-l-1-5 p-r-1-5 z-2 flex flex-justify-sb flex-align-c'>
      <router-link class='d-b h-1-rem' to='/'><img src="~@/assets/images/back-arrow.svg" alt="go back"></router-link>
      <span class='project-title text-sans'>{{ currentProject.name }}</span>
      <button class='light' @click="showOptionsMenu = !showOptionsMenu">
        More
        <img src="~@/assets/images/downarrow.svg" alt="">
      </button>
      <div class="options-menu" :class="{'active':showOptionsMenu}">
        <ul>
          <li>
            <a target="blank" :href="`https://millionexperiments.com/projects/${currentProject.slug}`">
              Full project page<img class="m-l-0-5" src='~@/assets/images/newwindow.svg' />
            </a>
          </li>
          <li>
            <a target="blank" href="https://millionexperiments.com/search">
              Browse all projects<img class="m-l-0-5" src='~@/assets/images/newwindow.svg' />
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <div class='project-detail__content pos-relative left-0 top-0 bg-white h-100-per z-1'>
      <div class='pos-relative w-100-per h-20-rem'>
        <img class='project-detail__content__image pos-absolute top-0 left-0 w-100-per h-100-per' :src="currentProject.featured_image" alt="">
      </div>
      <div class='p-2 p-t-1-5'>
        <h1 class='text-align-c'>{{ currentProject.name }}</h1>
        <p class='text-sans text-align-c m-b-2'>
          <span v-if="currentProject.based_in || currentProject.state" class='text-bold'>
            {{currentProject.based_in || ''}}
            {{currentProject.state ? `, ${unslugify(currentProject.state)}` : ''}}
          </span></p>
        <sound-cloud-player
          v-if="currentProject.related_podcasts.length > 0"
          :episodeSlug="currentProject.related_podcasts[0].soundcloud_episode_slug"
          class="m-b-2"/>
        <div v-if="currentProject.related_podcasts.length > 0" v-html='currentProject.related_podcasts[0].summary' />
        <div v-else v-html='currentProject.body' />
        <div class="detail-footer">
          <p>
            <a
              class="sc-link p-r-1 m-r-1"
              v-if="currentProject.related_podcasts.length > 0"
              target="blank"
              :href="currentProject.related_podcasts[0].podcast_link">
              View in Soundcloud<img class="m-l-0-5 m-r-1" src='~@/assets/images/newwindow.svg' />
            </a>
            <a target="blank" href="https://podcasts.apple.com/us/podcast/one-million-experiments/id1589966282">
              View in iTunes<img class="m-l-0-5" src='~@/assets/images/newwindow.svg' />
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import {unslugify} from './../utils';
import SoundCloudPlayer from './SoundCloudPlayer.vue';
export default {
  components: {
    SoundCloudPlayer
  },
  data () {
    return {
      showOptionsMenu: false,
      unslugify
    }
  },
  computed: {
    ...mapState('city', ['currentProject'])
  }
};
</script>
<style lang='scss' scoped>
.project-detail{
  nav{
    border-radius: 7px 7px 0 0;
    border-bottom: 1px solid #dddddd;
    position: relative;
    .project-title{
      max-width: 60%;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 0.6rem;
    }
    .options-menu{
      position: absolute;
      top: 3.4rem;
      right: 1.6rem;
      background-color: white;
      border-radius: 7px;
      box-shadow: 0px 4px 30px 3px rgba(0, 0, 0, 0.20);
      max-width: 18rem;
      padding: 0.9rem 1.5rem;
      color: #878d98;
      visibility: hidden;
      transform: translateY(-10px);
      opacity: 0;
      transition:all 0.25s ease-in-out;
      &.active{
        visibility: visible;
        transform: translateY(0px);
        opacity: 1;
      }
      ul{
        margin: 0;
        padding: 0;
        list-style: none;
        font-family:$sans;
        font-size: 0.95rem;
        li{
          margin: 0.15rem 0;
          cursor: pointer;
          display: flex;
          justify-content: start;
          vertical-align: middle;
          img{
            margin-right:0.75rem;
          }
        }
      }
    }
  }
  .detail-footer{
    margin-top: 2rem;
    background-color: #ececec;
    padding: 0rem 0.5rem;
    border-radius: 75px;
    text-align: center;
    p{
      margin:0;
      font-family:$sans;
      a{
        display: inline-block;
      }
      .sc-link{
        border-right:1px solid white;
        padding:0.8rem 0;
      }
    }
  }
  &__content{
    box-shadow: -8px -5px 40px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 7px 7px;
    overflow-y:scroll;
    &__image{
      object-fit:cover;
    }
  }
}
</style>
