<template>
  <span class="loader"></span>
</template>
<style scoped lang='scss'>
.loader {
  position: relative;
  margin: auto;
  box-sizing: border-box;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid $color-dark-blue;
  transform-origin: 50% 50%;
  transform: perspective(200px) rotateX(57deg) scale(1.25);
  animation: spinner-wiggle 1.2s infinite;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  inset: -4px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 4px solid $color-dark-blue;
  animation: spinner-spin 1.2s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
    spinner-fade 1.2s linear infinite;
}
.loader:before {
  border-top-color: #fff;
}
.loader:after {
  border-top-color: $color-green;
  animation-delay: 0.4s;
}

@keyframes spinner-spin {
  100% { transform: rotate(360deg)}
}
@keyframes spinner-fade {
  25%, 75% { opacity: 0.1}
  50% { opacity: 1 }
}

</style>
