// import {axiosInstance} from '@/api/endpoints'
import OpenSeadragon from 'openseadragon';
import { projects } from "./../../data";
import router from './../../router';
import {
  axiosInstance,
  PROJECTS_BY_TAG_SLUG,
  PROJECT_BY_SLUG
} from './../../api/endpoints';

const state = () => ({
  viewer: null,
  screenType: 'narrow',
  projects: null,
  currentProject: null,
  loadingProject: null,
  loading: true
})

const mutations = {
  setScreenWidth (state) {
    state.screenType = window.innerWidth > 879 ? 'wide' : 'narrow';
  },
  setProjects (state, payload) {
    payload.a.forEach(project => {
      project['x'] = Number(project.city_x);
      project['y'] = Number(project.city_y);
      project['zoom'] = 3.5;
      project['id'] = project.slug;
      project['placement'] = 'BOTTOM_RIGHT';
      // project['organization'] = project.name;
      // project['place'] = project.based_in
      // media: {
      //   heroImage: 'guns-down-hero.jpg',
      //   podcast: {
      //     title: "Airgo Episode #4",
      //     link: "https://www.apple.com/apple-podcasts/"
      //   },
      //   zine: "https://files.cargocollective.com/c1000528/NYCTJH_ZINE-Pages.pdf",
      //   contact: "backyardsquabble@gmail.com"
      // },


    })
    console.log(payload)
    state.projects = payload.a
  },
  setCurrentProject (state, payload) {
    state.currentProject = payload
  },
  setProjectLoadingState (state, payload) {
    state.loadingProject = payload
  }
}

const actions = {

    initMap ({state}) {
      // start loading:
      return new Promise((resolve) => {
        const loadCheck = setInterval(() => {
          if (state.viewer) {
            const firstLoadItem = state.viewer.world.getItemAt(0)
            if (firstLoadItem) {
              resolve()
              state.loading = false
              clearInterval(loadCheck)
              const firstLoad = firstLoadItem.getFullyLoaded()
              if (firstLoad) {
                // When map is built hide loader
                // resolve()
                // state.loading = false
                // clearInterval(loadCheck)
              }
            }
          }
        }, 500)

        // build the viewer:
        const minZoom = state.screenType === 'narrow' ? 4 : 2.5;
        state.viewer = OpenSeadragon({
          id: 'cityscape',
          visibilityRatio: 1.3,
          constrainDuringPan: true,
          defaultZoomLevel: 2.3,
          minZoomLevel: minZoom,
          maxZoomLevel: 5,
          homeFillsViewer: false,
          preserveImageSizeOnResize: false,
          animationTime: 1.0,
          gestureSettingsMouse: {
            scrollToZoom: true
          },
          homeButton: 'reset',
          springStiffness: 6,
          minPixelRatio: 0.2,
          showNavigationControl: false,
          tileSources: 'https://1me.s3.amazonaws.com/1me.dzi',
          image: {
            xmlns: 'http://schemas.microsoft.com/deepzoom/2008',
            Format: 'jpg',
            Overlap: 2,
            TileSize: 256,
            Size: {
              Height: 9221,
              Width: 7026
            }
          },
          overlays: state.projects,
          crossOriginPolicy: "Anonymous"
        })
        let startingCoords = {x:0, y:0};
        const drag = {x: false, y: false};
        // build the viewer:
        state.projects.forEach(project => {
          new OpenSeadragon.MouseTracker({
            element: project.id,
            preProcessEventHandler: function (eventInfo) {
              if (eventInfo.eventType === 'pointerdown') {
                startingCoords.x = eventInfo.originalEvent.clientX
                startingCoords.y = eventInfo.originalEvent.clientY
              } else if (eventInfo.eventType === 'pointerup') {
                if (eventInfo.originalEvent.clientX > startingCoords.x || eventInfo.originalEvent.clientX < startingCoords.x) {
                  console.log('drag x occurred.')
                  drag.x = true;
                } else {
                  drag.x = false;
                  console.log('no drag x.')
                }
                if (eventInfo.originalEvent.clientY > startingCoords.y || eventInfo.originalEvent.clientY < startingCoords.y) {
                  console.log('drag y occurred.')
                  drag.y = true;
                } else {
                  drag.y = false;
                  console.log('no drag y.')
                }
              }
            },
            clickHandler: function(e) {
              if (drag.x || drag.y) {
                e.stopPropagation = true
                console.log('drag is true')
              } else {
                e.stopPropagation = false
                console.log('no drag')
                router.push(project.slug)
              }
            }
          });
        });
      })
    },
    getAllProjects({commit}) {
      return new Promise((resolve, reject) => {
          axiosInstance.get(`${PROJECTS_BY_TAG_SLUG}city-landmark`)
            .then((resp) => {
              resolve(resp);
              commit('setProjects', {a: resp.data, b: projects});
            })
            .catch((err) => {
              console.log(err)
              reject(err)
            })
      })
    },
    getProject({commit}, slug) {
      return new Promise((resolve, reject) => {
        axiosInstance.get(`${PROJECT_BY_SLUG}${slug}`)
        .then((resp) => {
          resolve(resp);
          commit('setCurrentProject', resp.data[0])
          // commit('setProjects', {a: resp.data, b: projects});
        })
        .catch((err) => {
          console.log(err)
          reject(err)
        })
        // setTimeout(() => {
          // const foundProject = projects.filter((project) => project.slug === slug);
          // if (foundProject.length > 0) {
          //   commit('setCurrentProject', foundProject[0])
          //   resolve(foundProject[0])
          // } else {
          //   return reject();
          // }
        // }, 0)
      })
  }
}

const city = {
    namespaced: true,
    state,
    mutations,
    actions
}

export default city;
