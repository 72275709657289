<template>
  <div id='main-menu' class='p-2 p-l-4 flex' :class='{"active": isActive}'>
    <!-- <ul class='menu-items li-style-none m-t-4'> -->
      <div>
        <h4 class="featured-title">Featured Projects:</h4>
        <div class="menu-items">
            <router-link
            class="menu-item"
            v-for='project in projects'
            :key='`menu-item${project.id}`'
            :to='`/${project.slug}`'>
            {{project.name}}
            </router-link>
        </div>
      </div>
      <div class="about">
        <h4>About One Million Experiments</h4>
        <p>One Million Experiments is a curated collection of community-based projects and podcast exploring how we define and create safety, wellness, and protection in a world without police and prisons. In the City of One Million Experiments you can explore our podcast episodes featuring interviews with movement workers across the world expanding our ideas about what keeps us safe, and celebrating the work already happening to build solutions that are grounded in transformation instead of punishment.</p>

        <p><a class="catalog-link" target="blank" href="https://millionexperiments.com">Explore the full catalog of projects</a></p>
        <div class="social">
          <ul class="flex flex-align-c p-0">
            <li class="m-r-1"><a target="blank" href="https://soundcloud.com/one-million-experiments"><img src="~@/assets/images/icons-soundcloud.svg" alt=""></a></li>
            <li class="m-r-1"><a target="blank" href="https://podcasts.apple.com/us/podcast/one-million-experiments/id1589966282"><img src="~@/assets/images/icons-applepodcasts.svg" alt=""></a></li>
            <li class="m-r-1"><a target="blank" href="https://www.instagram.com/interruptcrim/"><img src="~@/assets/images/icons-instagram.svg" alt=""></a></li>
            <li class="m-r-1"><a target="blank" href="https://twitter.com/interruptcrim"><img src="~@/assets/images/icons-twitter.svg" alt=""></a></li>
            <li><a target="blank" href="https://www.facebook.com/interruptcrim/"><img src="~@/assets/images/icons-fb.svg" alt=""></a></li>
          </ul>
        </div>
      </div>
      <!-- <li><h2>About #1ME City</h2></li>
      <li><h2>Our Platform</h2></li> -->
    <!-- </ul> -->
  </div>
</template>
<script>
export default {
  props: ['projects', 'activeClass'],
  data () {
    return {
      isActive: false
    }
  },
  beforeUnmount () {
    this.isActive = false
  },
  mounted () {
    setTimeout(() => {
      this.isActive = true
    }, 300)
  }
}
</script>
<style scoped lang='scss'>
#main-menu{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-dark-blue;
  color: #ffffff;
  padding-top: 7rem;
  align-items: baseline;
  justify-content: center;
  .featured-title{
    color: #a2b9f3;
  }
  h4{
    font-family: $sans;
    font-size:0.94rem;
    font-weight: 600;
    line-height: 1rem;
    margin-bottom:1rem;
    color: #a2b9f3;
  }
  p{
    font-family: $sans;
    font-size: 0.9rem;
  }
  .about{
    // border-top:1px solid white;
    // padding-top:1rem;
    // margin-top:3rem;
    // max-width: 30rem;
    // color:#a2b9f3;
    position: relative;
    border-left: 1px solid #3f5693;
    padding-left: 2rem;
    margin-top: 3rem;
    max-width: 22rem;
    color: #a2b9f3;
    padding-bottom: 3rem;
    margin-left: 4rem;
    .catalog-link{
      outline: none;
      border: 1px solid #a2b9f3;
      background-color: #233660;
      color: #a2b9f3;
      padding: 0.65rem 1rem;
      font-weight: 400;
      font-family: Circular;
      font-size: 0.85rem;
      line-height: 1rem;
      border-radius: 2rem;
      cursor: pointer;
      margin-top: 1rem;
      display: inline-block;
    }
    .social{
      position: absolute;
      bottom:0;
      ul{
        margin:0;
        transform: scale(0.9);
        li{
          list-style-type: none;
        }
      }
    }
  }
  .menu-items{
    position: relative;
    transform: translate3d(0px, 20px, 0px);
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    opacity: 0;
    display: flex;
    flex-direction: column;
    .menu-item{
      color: #fff;
      font-family: $sans;
      font-weight: 400;
      font-size: 1.1rem;
      margin-bottom: 0.6rem;
      transition: all 0.2s ease-in-out;
      &:hover{
        color:#a2b9f3;
        transform:translate3d(10px, 0, 0);

      }
    }
  }
  &.active{
    .menu-items{
      transform: translate3d(0px, 0px, 0px);
      opacity: 1;
      // transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s;
    }
  }
}
</style>
