<template>
  <nav class='flex text-sans pos-fixed w-100-per flex-align-c p-1-5' :class="{'menuOpen': menuOpen}">
    <button class='m-r-1' @click='$emit("toggleMenu")'>{{menuOpen ? 'Close' : 'Menu'}}</button>
    <img style="width:16.5rem;" src="~@/assets/images/wordmark.svg" alt="city of one million experiments">
    <button class='full-catalog-link m-r-1 flex flex-align-c' @click="goToCatalog">View Full Project Catalog <img class="m-l-0-5" src='~@/assets/images/newwindow.svg' /></button>
  </nav>
</template>
<script>
  export default {
    props:['menuOpen'],
    methods: {
      goToCatalog () {
        window.location.href = 'https://millionexperiments.com'
      }
    }
  }
</script>
<style scoped lang='scss'>
nav{
  .full-catalog-link{
    background-color: white;
    color:$color-dark-blue;
    margin-left: auto;
    box-shadow: 3px 2px 10px 0px rgba(0, 0, 0, 0.14);
  }
  background: linear-gradient(0deg, rgba(237, 229, 208, 0%) 0%, $color-green 90%, $color-green 100%);
  &.menuOpen{
    background: linear-gradient(0deg, rgba(237, 229, 208, 0%) 0%, $color-dark-blue 90%, $color-dark-blue 100%);
  }
}
</style>
