<template>
<div class='onemillion-loading-view'>
  <!-- <div class='loading-element'> -->
    <Loader class='loading-element' />
    <p>Loading city...</p>
  <!-- </div> -->
</div>
</template>
<script>
import Loader from './Loader.vue';
export default {
  components: {
    Loader
  }
}
</script>
<style scoped lang='scss'>
.onemillion-loading-view{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:$color-dark-blue;
  display: flex;
  align-items: center;
  flex-direction:column;
  p{
    font-family: $sans;
    font-weight:500;
    color:#fff;
    text-align: center;
    position: absolute;
    font-size: 0.9rem;
    top: 50%;
    transform: translateY(-60%);
  }
  .loading-element{
    // position: absolute;
    // top:50%;
    // left:50%;
    // transform: translateX(-50%) translateY(-50%);
  }
}
</style>
