export const projects = [
  {
    name: "NYC TJ Hub",
    slug: "nyc-tj-hub",
    id: "nyc-tj-hub",
    body: "<p>In 2019, Mariame Kaba brought together a group of seasoned Transformative Justice practitioners and New York City organizers to explore the formation of a Transformative Justice and community accountability (CA) training and support program pilot for New York City.</p><p>Taking direction from previous Transformative Justice collective configurations — most notably the Bay Area Transformative Justice Collective — the group formed the New York City Transformative Justice Hub. For one year, from Fall 2019 to Summer 2020, the Hub ran bi-monthly political education workshops for the public followed by a consultation space for groups already involved in CA work to troubleshoot processes. By “political education,” we mean that we shaped the events to focus on knowledge and skill-building rooted in PIC abolition, transforming violence, and liberation.</p><p>The initial Hub members consisted of community organizers well-versed in TJ and PIC abolition literature and theory but relatively new to TJ/CA practice. Through Kaba and other TJ facilitators, the group members were connected to a larger network of experienced practitioners. In each workshop session, the Hub brought in practitioners from that network to share a particular concrete skill.</p>",
    organization: "New York City Transformative Justice Hub",
    place: "New York City, NY",
    mediaElements: {
      heroImage: '~/assets/images/guns-down-hero.jpg',
      podcast: {
        title: "Airgo Episode #1",
        link: "https://www.apple.com/apple-podcasts/"
      },
      zine: "https://files.cargocollective.com/c1000528/NYCTJH_ZINE-Pages.pdf",
      contact: "New York City Transformative Justice Hub"
    },
    x: 0.38,
    y: 0.41,
    zoom: 3.5,
    placement: 'BOTTOM_RIGHT'
  },
  {
    name: "He Ara Mataora",
    slug: "he-ara-mataora",
    id: "he-ara-mataora",
    body: "<p>mataora.wananga.com “is for people who want to stop violence that they are seeing or experiencing, but who don’t want to rely on the police and courts, social workers or agencies to help. It uses a community-based approach to stop violence.</p><p>The website is a collaboration between Te Wānanga o Raukawa and Creative Interventions. It is based on the Creative Interventions toolkit: A practical guide to stop interpersonal violence. “This website will not tell you what to do or give you a process to follow.  The tools are intended to support you to work out how to respond to, end or prevent violence in ways most likely to be safe and successful.</p><p>The site contains information about interpersonal violence—where someone is hurting or has hurt someone else, and resources for people who want to end violence. Whether you have been or are being hurt, you are hurting someone, you care about those people or you see what is happening and want to help.</p>",
    organization: "Te Wānanga o Raukawa",
    place: "New Zealand",
    mediaElements: {
      heroImage: '~/assets/images/guns-down-hero.jpg',
      podcast: {
        title: "Airgo Episode #2",
        link: "https://www.apple.com/apple-podcasts/"
      },
      zine: "https://files.cargocollective.com/c1000528/NYCTJH_ZINE-Pages.pdf",
      contact: "mataora@twor-otaki.ac.nz"
    },
    x: 0.41,
    y: 0.261,
    zoom: 3.5,
    placement: 'BOTTOM_RIGHT'
  },
  {
    name: "Men Making a Difference",
    slug: "men-making-a-difference",
    id: "men-making-a-difference",
    body: "<p>“‘It started out as dominoes,’ [Robert “Rock”] Calhoun said, looking toward a makeshift wooden table in one of the lots. ‘We played dominoes from sun up to sun down sometimes. It got cold, we dug a pit, [and] we burnt wood to stay warm… A group of men joined up with us, there was 36 of us at one time.’</p><p>Crowds of people from the neighborhood would gather to observe the game and listen to their banter, jokes and spiritual discussions, he said. Calhoun and Charlie Wilson, close friends since the childhood they shared in the neighborhood, realized they had an opportunity to make the domino games something more.</p><p>‘We said, you know what, we need to stand up and show people there are men in our community who are willing to stand on the front line,’ Wilson said. ‘So this organization was a great tool for men to come together to change their mindset. It begins to take fear out of the minds of the people in the community.’</p>",
    organization: "Men Making a Difference",
    place: "Chicago, IL",
    mediaElements: {
      heroImage: '~/assets/images/guns-down-hero.jpg',
      podcast: {
        title: "Airgo Episode #3",
        link: "https://www.apple.com/apple-podcasts/"
      },
      zine: "https://files.cargocollective.com/c1000528/NYCTJH_ZINE-Pages.pdf",
      contact: "mmadofchgo@gmail.com"
    },
    x: 0.577,
    y: 0.278,
    zoom: 3.5,
    placement: 'BOTTOM_RIGHT'
  },
  {
    name: "Guns Down, Squabble Up",
    slug: "guns-down-squabble-up",
    id: "guns-down-squabble-up",
    body: "<p>“Fighting under the motto ‘guns down, squabble up,’ promoters DGoot and Josh Brito give fighters a space to settle disputes outside the streets. In one of the fights, dubbed a ‘beef fight,’ we saw two men resolve their problem in the ring instead of in the streets, which often has deadly consequences.</p><p>Josh tells L.A. TACO, ‘At this point, we are trying to create a platform for fighters to grow. Whether they take it to the next level or just bring it in the ring to squash beef.’ Gun violence has increased significantly over the first half of 2021. Backyard Squabbles hopes to give Los Angeles residents a way to settle disputes without bringing a gun into the equation. ‘Our group offers an alternative to gun violence in our communities. Put the guns away, live to fight another day,’ Josh continues.</p><p>Backyard Squabbles provides the perfect setting for fighters who are just starting to get an in-the-ring experience that is hard to find anywhere else. More traditional avenues for fighters to move up the ranks, like AAU (Amateur Athletic Union) boxing, are very exclusive and make it next-to-impossible for younger, more inexperienced fighters to get exposure.</p>",
    organization: "Backyard Squabbles",
    place: "Los Angeles, CA",
    media: {
      heroImage: 'guns-down-hero.jpg',
      podcast: {
        title: "Airgo Episode #4",
        link: "https://www.apple.com/apple-podcasts/"
      },
      zine: "https://files.cargocollective.com/c1000528/NYCTJH_ZINE-Pages.pdf",
      contact: "backyardsquabble@gmail.com"
    },
    x: 0.479,
    y: 0.4315,
    zoom: 3.5,
    placement: 'BOTTOM_RIGHT'
  },
  {
    name: "Guns Down, Squabble Up 2",
    slug: "guns-down-squabble-up2",
    id: "guns-down-squabble-up2",
    body: "<p>“Fighting under the motto ‘guns down, squabble up,’ promoters DGoot and Josh Brito give fighters a space to settle disputes outside the streets. In one of the fights, dubbed a ‘beef fight,’ we saw two men resolve their problem in the ring instead of in the streets, which often has deadly consequences.</p><p>Josh tells L.A. TACO, ‘At this point, we are trying to create a platform for fighters to grow. Whether they take it to the next level or just bring it in the ring to squash beef.’ Gun violence has increased significantly over the first half of 2021. Backyard Squabbles hopes to give Los Angeles residents a way to settle disputes without bringing a gun into the equation. ‘Our group offers an alternative to gun violence in our communities. Put the guns away, live to fight another day,’ Josh continues.</p><p>Backyard Squabbles provides the perfect setting for fighters who are just starting to get an in-the-ring experience that is hard to find anywhere else. More traditional avenues for fighters to move up the ranks, like AAU (Amateur Athletic Union) boxing, are very exclusive and make it next-to-impossible for younger, more inexperienced fighters to get exposure.</p>",
    organization: "Backyard Squabbles",
    place: "Los Angeles, CA",
    media: {
      heroImage: 'guns-down-hero.jpg',
      podcast: {
        title: "Airgo Episode #4",
        link: "https://www.apple.com/apple-podcasts/"
      },
      zine: "https://files.cargocollective.com/c1000528/NYCTJH_ZINE-Pages.pdf",
      contact: "backyardsquabble@gmail.com"
    },
    x: 0.581,
    y: 0.319,
    zoom: 3.5,
    placement: 'BOTTOM_RIGHT'
  },
  {
    name: "Guns Down, Squabble Up3",
    slug: "guns-down-squabble-up3",
    id: "guns-down-squabble-up3",
    body: "<p>“Fighting under the motto ‘guns down, squabble up,’ promoters DGoot and Josh Brito give fighters a space to settle disputes outside the streets. In one of the fights, dubbed a ‘beef fight,’ we saw two men resolve their problem in the ring instead of in the streets, which often has deadly consequences.</p><p>Josh tells L.A. TACO, ‘At this point, we are trying to create a platform for fighters to grow. Whether they take it to the next level or just bring it in the ring to squash beef.’ Gun violence has increased significantly over the first half of 2021. Backyard Squabbles hopes to give Los Angeles residents a way to settle disputes without bringing a gun into the equation. ‘Our group offers an alternative to gun violence in our communities. Put the guns away, live to fight another day,’ Josh continues.</p><p>Backyard Squabbles provides the perfect setting for fighters who are just starting to get an in-the-ring experience that is hard to find anywhere else. More traditional avenues for fighters to move up the ranks, like AAU (Amateur Athletic Union) boxing, are very exclusive and make it next-to-impossible for younger, more inexperienced fighters to get exposure.</p>",
    organization: "Backyard Squabbles",
    place: "Los Angeles, CA",
    media: {
      heroImage: 'guns-down-hero.jpg',
      podcast: {
        title: "Airgo Episode #4",
        link: "https://www.apple.com/apple-podcasts/"
      },
      zine: "https://files.cargocollective.com/c1000528/NYCTJH_ZINE-Pages.pdf",
      contact: "backyardsquabble@gmail.com"
    },
    x: 0.6000513876638105,
    y: 0.40865373058158516,
    zoom: 3.5,
    placement: 'BOTTOM_RIGHT'
  }
]
