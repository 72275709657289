<template>
  <span class="point-loader"></span>
</template>
<style scoped lang='scss'>
.point-loader {
  position: absolute;
  margin: auto;
  box-sizing: border-box;
  bottom: -324px;
  right: -157px;
  width: 320px;
  height: 320px;
  border-radius: 50%;
  // border: 8px solid rgba(255, 255, 255, 0.1);
  transform-origin: 50% 50%;
  transform: rotateX(312deg) rotateY(48deg) rotateZ(91deg);
  animation: spinner-wiggle 1.2s infinite;
}
.point-loader:before,
.point-loader:after {
  content: "";
  position: absolute;
  inset: -4px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 4px solid #0000;
  animation: spinner-spin 1.2s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
    spinner-fade 1.2s linear infinite;
}
.point-loader:before {
  border-top-color: #414141;
}
.point-loader:after {
  border-top-color: #F17666;
  animation-delay: 0.4s;
}

@keyframes spinner-spin {
  100% { transform: rotate(360deg)}
}
@keyframes spinner-fade {
  25%, 75% { opacity: 0.1}
  50% { opacity: 1 }
}

</style>
