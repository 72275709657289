import axios from 'axios'

// Set config defaults when creating the instance
export const axiosInstance = axios.create({
    // baseURL: 'http://127.0.0.1:8000/api/v1/'
    baseURL: 'https://onemillionexperiments-api.herokuapp.com/api/v1/'
});
// export const PROJECTS = 'projects/'
// export const PODCASTS = 'podcasts/'
// export const SUBMISSIONS = 'submissions/'

export const PROJECT_BY_SLUG = 'projects/?project-slug='
// export const PODCAST_BY_SLUG = 'podcasts/?podcast-slug='

export const PROJECTS_BY_TAG_SLUG = 'projects/?project-tags='
// export const PROJECTS_BY_ID_SET = 'projects/?project-ids='
// export const PODCASTS_BY_TAG_SLUG = 'podcasts/?podcast-tags='

// export const CATEGORIES_BY_FRONTPAGE = 'tags/?front-page=1'
