<template>
  <div class='main-view pos-fixed w-100-per h-100-per'>
    <!-- <top-nav @toggleMenu='mainMenuActive = !mainMenuActive' class='z-10' />
    <main-menu v-if='mainMenuActive' :projects='projects' class='z-9' /> -->


    <div class="screenSizeMessage p-2 max-w-35-rem m-0-auto" v-if="screenType === 'narrow' || overrideShowScreenMessage">
      <img class="m-b-2" src="~@/assets/images/wordmarklight.svg" alt="city of one million experiments"/>
      <img src="~@/assets/images/screensize.svg" alt="">
      <h5 class="m-t-2 screensize-header">Screen size not supported yet 😬.</h5>
      <p class="screensize-body">We're sorry but One Million City is not yet available on this screen size. Please switch to a desktop browser screen.</p>
      <ul class="m-0 p-0 m-t-4">
          <li>
            <a target="blank" href="https://soundcloud.com/one-million-experiments">
              Soundcloud <img class="m-l-0-5" src='~@/assets/images/newwindow.svg' />
            </a>
          </li>
          <li>
            <a target="blank" href="https://onemillionexperiments-platform.herokuapp.com/">
              millionprojects.com <img class="m-l-0-5" src='~@/assets/images/newwindow.svg' />
            </a>
          </li>
      </ul>
    </div>

    <div v-else>


      <transition name="intro-fade">
        <div id="intro-view" v-if="introState && !loading">
          <div class="message-box flex flex-column flex-align-c">
            <img src="~@/assets/images/welecometoonmillion.svg" alt="">
            <img class="m-t-2 m-b-1" src="~@/assets/images/panzooom.svg" alt="">
            <p class="text-center">Explore featured projects from different communities by panning and zooming around our town. Select points of interest to listen to episodes of the One Million Experiments podcast.</p>
            <button @click="dismissIntro" class="white m-t-1 flex flex-align-c p-1 p-l-2 p-r-2">Enter <img class="m-l-0-5" src="~@/assets/images/enter-arrow.svg" alt=""></button>
          </div>
        </div>
      </transition>

      <city-scape class='z-2' />
      <transition name="fade">
        <city-loading-view v-if='loading' class='z-10' />
      </transition>
    </div>


  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import OpenSeadragon from 'openseadragon';
import CityScape from "./../components/CityScape.vue";
import CityLoadingView from './../components/CityLoadingView.vue';
// import MainMenu from './../components/MainMenu.vue';
// import TopNav from "./../components/TopNav.vue";
export default {
  components: {
    CityLoadingView,
    CityScape,
    // MainMenu,
    // TopNav
  },
  computed: {
    ...mapState('city', ['viewer', 'currentProject', 'loading', 'projects', 'screenType']),
  },
  data () {
    return {
      introState: true,
      overrideShowScreenMessage: false
    }
  },
  watch: {
    $route: function (newRoute) {
      if (newRoute && newRoute.params.projectSlug) {
        this.mapToProject();
      } else {
        this.setCurrentProject(null);
        this.viewer.viewport.goHome(false);
      }
    }
  },
  methods: {
    ...mapMutations('city', ['setScreenWidth', 'setCurrentProject', 'setProjectLoadingState']),
    ...mapActions('city', ['initMap', 'getAllProjects', 'getProject']),
    dismissIntro () {
      this.introState = false;
      // const point = new OpenSeadragon.Point(0.5, 0.26)
      // this.viewer.viewport.panTo(point, false)
      this.viewer.viewport.zoomTo(2.5, false)
    },
    hackyCheckScreenWidth () {
      let timeout = false;
      const delay = 250;

      // window.resize event listener
      window.addEventListener('resize',() => {
        // clear the timeout
        clearTimeout(timeout);
        // start timing for event "completion"
        timeout = setTimeout(() => {
          if (window.innerWidth > 879) {
            this.overrideShowScreenMessage = false;
            this.startApp();
          } else {
            this.overrideShowScreenMessage = true;
          }
        }, delay);
      });
    },
    mapToProject () {
      // 1. if project slug is real project item, unset any existing project
      // 2. go to point
      // 3. load project - kick off loading state
      // 4. get project
      // 5. complete loading state
      // 6. open project drawer
      // 7. if project is not real project item, route back home
      const slug = this.$route.params.projectSlug;

      const foundProject = this.projects.filter((project) => project.slug === slug);
      if (foundProject.length > 0) {

        this.setCurrentProject(null);

        const point = new OpenSeadragon.Point(foundProject[0].x, foundProject[0].y)
        const zoomVal = foundProject[0].zoom
        this.viewer.viewport.zoomTo(zoomVal, false)
        this.viewer.viewport.panTo(point, false)

        this.setProjectLoadingState(foundProject[0]);

        setTimeout(()=> {
          this.getProject(slug)
            .then(() => {
              this.setProjectLoadingState(null);
            })
            .catch(() => {console.log('oops')})
        }, 0)

      } else {
        this.$router.push('/')
      }
    },
    startApp () {
      this.getAllProjects()
        .then(() =>{
          // when required data is loaded,
          // Build city tile map:
          this.initMap()
            .then(() => {
              // if we're on a project route...
              if (this.$route.params.projectSlug) {
                this.mapToProject();
              } else {
                const point = new OpenSeadragon.Point(0.5, 0.26)
                this.viewer.viewport.panTo(point, false)
              }
            })
        })
    }
  },
  created () {
    this.setScreenWidth();
    this.hackyCheckScreenWidth();

    if (this.screenType === 'narrow') {
      this.mobileMessage = true;
    } else {
      this.startApp();
    }
  },
};
</script>
<style lang='scss'>
.main-view{
  background-color: $color-dark-blue;
  #intro-view{
    position: fixed;
    z-index: 9999;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(10px);
    .message-box{
      background-color: $color-dark-green;
      max-width:30rem;
      padding:3rem;
      position: relative;
      top:50%;
      margin:0 auto;
      transform: translateY(-50%);
      box-shadow: 20px 24px 11px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      p{
        color:white;
        font-family:$sans;
      }
    }
  }
  .screenSizeMessage{
    position: relative;
    top:50%;
    transform:translateY(-50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-family:$sans;
    .screensize-header{
      font-family: $sans;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
    .screensize-body{
      color:#8494be;
    }
    ul{
      list-style-type: none;
      li{
        a{
        color:#fff;
        }
      }
    }
  }
}
.scaleFade-enter-active,
.scaleFade-leave-active {
  transition: all 0.35s ease;
  opacity: 1;
  transform: scale(1);
}

.scaleFade-enter-from,
.scaleFade-leave-to {
  opacity: 0;
  transform: scale(0.8);
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.35s ease;
  opacity: 1;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.intro-fade-enter-active,
.intro-fade-leave-active {
  transition: all 0.35s ease;
  opacity: 1;
  transform: scale(1);
}

.intro-fade-enter-from,
.intro-fade-leave-to {
  opacity: 0;
  transform: scale(0.8);
}
</style>
