<template>
  <nav class='pos-fixed'>
    <ul class="li-style-none m-0" v-if="projects">
      <li
        v-for='(project, i) in projects'
        :key='`project-${i}`'
        class='pos-relative p-t-1-5 p-l-2 p-r-2 p-b-1-5'>
        <router-link class='pos-absolute flex flex-justify-c flex-align-c w-100-per h-100-per top-0 left-0' :to='project.slug'>
          <div class="point pos-relative">
            <svg
              class='pos-absolute top-0 left-0 circle'
              x="0px"
              y="0px"
              width="100%"
              height="100%"
              viewBox="0 0 106 106"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                class="inner"
                cx="53"
                cy="53"
                r="60"
                fill="rgba(255, 14, 84, 0.17)"
              />
              <circle
                class="outer"
                cx="53"
                cy="53"
                r="180"
                fill="rgba(255, 14, 84, 0.17)"
              />
            </svg>
          </div>
        </router-link>
        <span
          class='point-title pos-absolute p-0-5 text-sans text-bold'
          :class='{active: $route.params.projectSlug === project.slug}'
          @click='$router.push(`/${project.slug}`)'>
          <router-link :to='project.slug' class='point-link'>
            {{project.name}}
          </router-link>
        </span>
      </li>
    </ul>
  </nav>
</template>
<script>
import {mapState} from 'vuex';
export default {
  computed: {
    ...mapState('city', ['projects'])
  }
}
</script>
<style lang='scss' scoped>
nav{
  top:50%;
  left:0;
  transform:translateY(-50%);
  ul{
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:after{
      position: absolute;
      content:'';
      display: block;
      width:1px;
      height:calc(100% - 3rem);
      top:1.5rem;
      left:50%;
      transform: translateX(-50%);
      background-color: $color-dark-blue;
      z-index: 0;
    }
    li{
      cursor: pointer;
      z-index: 1;
      .point {
        width: 8px;
        height: 8px;
        overflow: visible;
        .circle {
          overflow: visible;
          .inner {
            fill: $color-dark-blue;
            stroke: none;
            transform-origin: center;
            transform: translate3d(0, 0, 0) scale(1);
            transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          }
          .outer {
            opacity: 0.4;
            fill: $color-dark-blue;
            stroke: none;
            transform-origin: center;
            transform: translate3d(0, 0, 0) scale(0.1);
            transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          }
        }
      }
      .point-title{
        min-width:13rem;
        font-size:0.9rem;
        position: absolute;
        top: 50%;
        left: 4rem;
        transform: translateX(-10px) translateY(-50%);
        text-align: center;
        visibility: hidden;
        opacity: 0;
        transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        background-color:$color-dark-blue;
        cursor: pointer;
        .point-link{
          color:$color-white;
        }
        &:after{
          position: absolute;
          top: 50%;
          left: -8px;
          transform: translateY(-50%);
          content: '';
          display: block;
          width: 0px;
          height: 0px;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 10px solid $color-dark-blue;
        }
        &.active{
          transform: translateX(0px) translateY(-50%);
          visibility: visible;
          opacity: 1;
        }
      }
      &:hover{
        .point-title{
          transform: translateX(0px) translateY(-50%);
          visibility: visible;
          opacity: 1;
          &.active{
            opacity: 1;
          }
        }
      }
      .router-link-exact-active{
        .point {
          .inner{
            transform: translate3d(0, 0, 0) scale(1.87);
          }
          .outer {
            transform: scale(1.15);
          }
        }
      }
    }
  }
}
</style>
