import { createRouter, createWebHistory } from 'vue-router'
import Home from './../views/Home.vue';
import ProjectDetail from './../components/ProjectDetail.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    children: [
      {
        path: '/:projectSlug',
        component: ProjectDetail,
      }
    ]
  },
  { path: '/:pathMatch(.*)*', redirect: { name: 'home' } },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
