<template>
  <div id='cityscape' class="cityscape pos-fixed w-100-per h-100-per" @click="DEBUG_POINT_TO_CONSOLE">
    <top-nav :menuOpen="mainMenuActive" @toggleMenu='mainMenuActive = !mainMenuActive' class='z-12' />
    <transition name="mainmenu">
      <main-menu v-if='mainMenuActive' :activeClass='mainMenuActive' :projects='projects' class='z-11' />
    </transition>
    <div
      v-for='place in projects'
      :key='place.id'
      :id='place.id'
      class='flag-container'
      :class='activeClass(place)'>
      <div class='flag'>
        <h6>{{place.name}}</h6>
        <!-- <div class='animated-marker'></div> -->
        <city-point-loader v-if='loadingProject && loadingProject.slug === place.slug' class='place-loader' />
      </div>
    </div>
    <city-nav class='z-10' />
    <transition :name="screenType">
      <router-view :viewer='viewer' />
    </transition>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import TopNav from "./../components/TopNav.vue";
import CityNav from "./../components/CityNav.vue";
import CityPointLoader from "./../components/CityPointLoader.vue";
import MainMenu from './../components/MainMenu.vue';
export default {
  components: {
    TopNav,
    CityNav,
    CityPointLoader,
    MainMenu
  },
  data () {
    return {
      mainMenuActive: false
    }
  },
  computed: {
    ...mapState('city', ['viewer', 'projects', 'screenType', 'currentProject', 'loadingProject'])
  },
  watch: {
    $route: function (newRoute) {
      if (newRoute && newRoute.params.projectSlug) {
        this.mainMenuActive = false
      }
    }
  },
  methods: {
    activeClass (place) {
      if (this.currentProject && this.$route.params.projectSlug) {
        if (this.$route.params.projectSlug === place.slug) {
          return 'active-place'
        } else {
          return 'inactive-place'
        }
      }
    },
    // loadingClass (place) {
    //   if (this.loadingProject && this.loadingProject.slug === place.slug) {
    //     return 'loading-place'
    //   } else {
    //     return ''
    //   }
    // },
    DEBUG_POINT_TO_CONSOLE () {
      // utility method for finding / adding point locations
      // Prints point data to console:
      // const { clientX, clientY } = event;
      // const pixel = new OpenSeadragon.Point(clientX, clientY)
      // const currentZoom = Number(this.viewer.viewport.getZoom(true))
      // const viewportPoint = this.viewer.viewport.pointFromPixel(pixel)
      // console.log(`currentZoom: ${currentZoom}`);
      // console.log(`OSD Location: ${viewportPoint}`);
    }
  }
}
</script>
<style lang='scss'>
.cityscape{
  top:0;
  left:0;
  background-color:#ede5d0;
  .flag-container{
    position: absolute;
    height: 184px;
    transition: opacity 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    // width: 200px;
    .flag{
      position: relative;
      background-color: $color-dark-blue;
      transform: rotateX(59deg) rotateY(34deg) rotateZ(0deg);
      padding: 1.5rem 1.5rem;
      white-space: nowrap;
      cursor: pointer;
      z-index: 1;
      top: 0;
      right: 0;
      &:before{
        content: '';
        display: block;
        position: absolute;
        top: 222px;
        right: -4px;
        height: 20px;
        width: 10px;
        border-radius: 50%;
        background-color: $color-dark-blue;
        transform: rotateX(66deg) rotateY(320deg) rotateZ(0deg);
        z-index: 2;
      }
      &:after{
        content: '';
        display: block;
        position: absolute;
        top:70px;
        right:0px;
        height:160px;
        width:2px;
        background-color: $color-dark-blue;
        z-index: 3;
      }
      h6{
        font-size: 1.4rem;
        color:white;
      }
      .animated-marker{
      // .pulsating-circle {
        position: absolute;
        right: -23px;
        bottom: -183px;
        transform: rotateX(51deg) rotateY(321deg) rotateZ(0deg);
        width: 50px;
        height: 50px;
        z-index: 1;
        opacity: 0;

        &:before {
          content: '';
          position: relative;
          display: block;
          width: 300%;
          height: 300%;
          box-sizing: border-box;
          margin-left: -100%;
          margin-top: -100%;
          border-radius: 100px;
          border: 4px solid #e74f3b;
          // background-color: #41414138;
          animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
        }
      }
    }
    &.inactive-place{
      opacity: 0;
    }
    &.active-place{
      .flag{
        .animated-marker{
          opacity: 1;
        }
      }
    }
  }
  &:before{
    content: '';
    display: block;
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:2.5rem;
    background: linear-gradient(0deg, rgba(237, 229, 208, 0%) 0%, $color-green 90%, $color-green 100%);
    z-index: 2;
  }
  &:after{
    content: '';
    display: block;
    position: fixed;
    top:0;
    left:0;
    z-index: 9;
    width:100%;
    height:100%;
    background: radial-gradient(transparent 0%, transparent 10%, transparent 20%, transparent 25%, $color-green 100%);
    pointer-events:none;
  }
}
.wide-enter-active,
.wide-leave-active {
  transition: all 0.35s ease;
}

.wide-enter-from,
.wide-leave-to {
  opacity: 0;
  transform: translate3d(60px, 0, 0);
}
.narrow-enter-active,
.narrow-leave-active {
  transition: all 0.35s ease;
}

.narrow-enter-from,
.narrow-leave-to {
  opacity: 0;
  transform: translate3d(0px, 60px, 0);
}
.mainmenu-enter-active,
.mainmenu-leave-active {
  transition: all 0.5s ease;
}

.mainmenu-enter-from,
.mainmenu-leave-to {
  opacity: 0;
}
@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}
</style>
