<template>
    <div class="player flex flex-align-c m-t-1-5">

        <iframe
            width="100%"
            height="130"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            :src="`${scBaseUrl}${episodeSlug}??utm_source=${utmSource}&utm_medium=${utmMedium}`">
        </iframe>

    </div>
</template>
<script>
export default {
    props: {
        episodeSlug: {
            type: String,
            required: false,
            default: 'ep-305-one-million-experiments-part-8-taller-salud-with-zinnia-alejandro-english'
        }
    },
    data () {
        return {
            scBaseUrl: "https://w.soundcloud.com/player/?url=https://soundcloud.com/",
            utmSource: "onemillionweb",
            utmMedium: "www"
        }
    },
    methods: {},
    mounted () {}
}
</script>
<style lang="scss" scoped>
.player{
    // border: 1px solid #DDD9D4;
    border-radius: 20px;
    // overflow: hidden;
    // padding:0 0.65rem;
    // @include responsive(padding, 0 0.65rem, (700px: 0.65rem, 1000px: 0 0.65rem));
    iframe{
        box-shadow: 11px 10px 20px 0px rgba(0, 0, 0, 0.09);
    }
}
</style>
